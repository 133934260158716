<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="점검항목별 개선목록"
        tableId="grid"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :gridHeight="gridHeight2"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="false"
              :isImmShow="true"
              ibmTaskTypeCd="ITT0000165"
              ibmTaskUnderTypeCd="ITTU000180"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridImpr"
        title="항목외 개선 목록"
        :columns="gridImpr.columns"
        :data="gridImpr.data"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        :gridHeight="gridHeight"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 개선요청 -->
            <c-btn v-if="editable && !disabled && popupParam.saiJointInspectionId" label="개선요청" :showLoading="false"  icon="add" @btnClicked="addImpr" />
            <!-- 즉시조치 -->
            <c-btn v-if="editable && !disabled && popupParam.saiJointInspectionId" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-check-impr-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiJointInspectionId: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'jointItemName',
            field: 'jointItemName',
            label: '점검항목',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            required: true,
            name: 'jointResultName',
            field: 'jointResultName',
            label: '점검결과',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'jointContents',
            field: 'jointContents',
            label: '비고',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            style: 'width:300px',
            type: 'custom',
            sortable: false
          },
        ],
        
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      deleteUrl: '',
      impListUrl: '',
      getInfoUrl: '',
      isComplete: false,
      isSave: false,
      isSave2: false,
    };
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 500) + 'px';
    },
    gridHeight2() {
      return String(this.contentHeight - 550) + 'px';
    },
    disabled() {
      return false;
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
    'count'() {
      this.getDetail();
      this.getImpList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getInfoUrl = selectConfig.sai.joint.impr.url;
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;

      this.getDetail();
      this.getImpList();
    },
    getDetail() {
      if (this.popupParam.saiJointInspectionId) {
        this.$http.url = this.getInfoUrl;
        this.$http.param = {saiJointInspectionId: this.popupParam.saiJointInspectionId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    getImpList() {
      if (this.popupParam.saiJointInspectionId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000165', 
          ibmTaskUnderTypeCd: 'ITTU000175', 
          relationTableKey: this.popupParam.saiJointInspectionId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    linkClick2(row) {
      this.linkClick(row, row);
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    research() {
      this.$emit('research');
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['grid1'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: '합동점검',
        relationTableKey: this.popupParam.saiJointInspectionId,
        ibmTaskTypeCd: 'ITT0000165',
        ibmTaskUnderTypeCd: 'ITTU000175', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: '합동점검',
        relationTableKey: this.popupParam.saiJointInspectionId,
        ibmTaskTypeCd: 'ITT0000165',
        ibmTaskUnderTypeCd: 'ITTU000175', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>
